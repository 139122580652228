import en from '../locale/en-US.json';
import de from '../locale/de-DE.json';
import ru from '../locale/ru-RU.json';
//@ts-ignore
export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en, de, ru },
  warnHtmlMessage: false
}));
